import {Component, NgZone, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Router} from '@angular/router';
import {register} from 'swiper/element/bundle';
import {SplashScreen} from '@aparajita/capacitor-splash-screen';
import {Subscription} from 'rxjs';
import {EventsService} from './services/events.service';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {LoadingHelper} from './helpers/loading.helper';
import {Preferences} from '@capacitor/preferences';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from './services/analytics.service';
import {PushService} from './services/push.service';
import {TranslatesService} from './services/translates.service';
import {UserModel} from "./models/user.model";
import {LabelsService} from "./services/labels.service";
import {FilterService} from "./services/filter.service";
import {App, URLOpenListenerEvent} from "@capacitor/app";
import * as Sentry from "@sentry/angular";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public currentPage: string;
  profileSubscription: Subscription;
  loginSubscription: Subscription;
  logoutSubscription: Subscription;
  splashSubscription: Subscription;
  public profile: UserModel;

  constructor(
    public platform: Platform,
    private menuCtrl: MenuController,
    private router: Router,
    private eventsSvc: EventsService,
    private apiSvc: ApiService,
    public authSvc: AuthService,
    private loadingHelper: LoadingHelper,
    private translateSvc: TranslateService,
    private analyticsSvc: AnalyticsService,
    private pushSvc: PushService,
    public translatesSvc: TranslatesService,
    private labelsSvc: LabelsService,
    private filterSvc: FilterService,
    private ngZone: NgZone
  ) {
    this.loginSubscription = this.eventsSvc.subscribe('sign-in', async (data) => {
      console.log('login event recieved', data);
      await this.onLogin(true, true);
    });

    this.logoutSubscription = this.eventsSvc.subscribe('logout', async (data) => {
      console.log('logout event recieved', data);
      await this.onLogout();
    });

    this.splashSubscription = this.eventsSvc.subscribe('splash', async (data) => {
      console.log('splash event recieved', data);
      await this.onSplashHide();
    });
  }

  public async setRoot(url: any): Promise<boolean> {
    console.warn("IN SET ROOT", URL)
    return await this.router.navigate([url], {replaceUrl: true});
  }

  async ngOnInit() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      Sentry.captureMessage("APP URL OPEN RESULT: " + JSON.stringify(event))
      this.ngZone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split("app.vip-host.techcrowd.space").pop(); //app.vipbenefitclub.cz
        console.warn("APP OPEN LINK: ", slug)
        if (slug) {
          //this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });

    });
    await this.apiSvc.init();
    await this.platform.ready();
    await this.translatesSvc.init();
    if (this.platform.is('ios') && this.platform.platforms().includes('capacitor')) {
      await StatusBar.setStyle({style: Style.Light});
    }
    await this.analyticsSvc.initFb();

    console.log('this.apiSvc.isLogged', this.apiSvc.isLogged);
    console.log('this.apiSvc.isValid', this.apiSvc.isValid);
    this.translateSvc.setDefaultLang('cs');
    //await this.geolocationSvc.geolocateMe();
    if (this.apiSvc.isLogged && this.apiSvc.isValid) {
      await this.onLogin(true);
    } else if (this.apiSvc.isLogged && !this.apiSvc.isValid) {
      await this.apiSvc.refresh();
      await this.onLogin(true);
      console.log('refresh');
    } else {
      console.log('logout');
      await this.apiSvc.logout();
    }

  }


  public async onSplashHide() {
    try {
      setTimeout(async () => {
        SplashScreen.hide({
          fadeOutDuration: 300
        }).catch((e) => {
          Sentry.captureException(JSON.stringify(e));

          console.log(e);
        });
        console.warn('==== AFTER SPLASH HIDE ====');
      }, 300);
    } catch (e) {
      Sentry.captureException(JSON.stringify(e));
      console.log(e);
    }
  }

  public async setRootPage(isLogged: boolean = false, afterLogin: boolean = false) {
    try {
      if (isLogged) {
        this.labelsSvc.getAllLabels();
        const resp = await this.authSvc.getLoggedUser();
        if (resp) {
          await Preferences.set({key: 'user', value: JSON.stringify(resp)});
          console.warn("LOGGED USER PROFILE: ", this.authSvc.profile);
          console.warn("afterLogin", afterLogin)
          console.warn("this.authSvc.profile.company", this.authSvc.profile.company)
          console.warn("this.authSvc.profile.company.length", this.authSvc.profile.company.length)
          console.warn("this.authSvc.profile.verified", this.authSvc.profile.verified)
          if (afterLogin && (this.authSvc.profile.company && this.authSvc.profile.company.length) && this.authSvc.profile.verified) {
            console.warn("IN SET ROOT FOR PAGE ''")
            return this.setRoot(''); //user is verified and has company
          }
          if (!this.authSvc.profile.company || !this.authSvc.profile.company.length) {
            console.warn("IN SET ROOT FOR PAGE VERIFICATION")
            return this.setRoot('/auth/verification'); //user without company
          }
          // TODO - resolve when redirect user to this page
          if (!this.authSvc.profile.verified) {
            console.warn("IN SET ROOT FOR PAGE ACTIVATION")
            return this.setRoot('/auth/activation'); //user no verified
          }
        }
      } else {
        return this.setRoot('/auth/sign-in');
      }
    } catch (e) {
      Sentry.captureException(JSON.stringify(e));
      console.log(e);
      return false;
    }

  }

  async ionViewWillLeave(): Promise<void> {
    //this.authSvc.user.unsubscribe();
  }

  async onMenuClose(): Promise<void> {
    this.profileSubscription.unsubscribe();
  }

  async onMenuOpen(): Promise<void> {
    this.currentPage = this.router.url;
    this.profileSubscription = this.authSvc.user.subscribe(async (user) => {
      this.profile = user;
      console.log('LOGGED USER: ', user);
      if (this.profile && !this.profile.verified) {
        return await this.setRoot('/auth/activation');
      }
    });
  }

  async closeMenu(): Promise<void> {
    this.filterSvc.selectedFilters.next([]);
    this.filterSvc.clearStoredFilters();
    await this.menuCtrl.close();
  }

  async logout(): Promise<void> {
    await this.loadingHelper.presentLoader(null, null, 'logout() app');
    try {
      await this.authSvc.logout();
      await this.apiSvc.logout();
      await this.menuCtrl.close();
      await this.loadingHelper.dismissLoader('logout() app');
    } catch (e) {
      Sentry.captureException(JSON.stringify(e));
      console.log(e);
      await this.loadingHelper.dismissLoader('logout() app error');

    }
  }

  private async onLogin(isLogged: boolean = false, afterLogin: boolean = false) {
    try {
      await this.setRootPage(isLogged, afterLogin);
      if (this.platform.platforms().includes('capacitor')) {
        await this.pushSvc.init();
      }

    } catch (e) {
      Sentry.captureException(JSON.stringify(e));
      console.log(e);
    }
  }

  private async onLogout(): Promise<void> {
    await this.setRootPage(false);
  }

}
