import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryUrl,
  release: `vip-bc@0.0.26`,
  // Set your dist version, such as "1"
  dist: `0.0.26`,
  integrations: [
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets:  ['localhost', 'https://dev-api-vipbc.techcrowd.space/api/', 'https://api-vipbc.techcrowd.space/api/'],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    Sentry.captureException(JSON.stringify(err));
    console.log(err)
  });
