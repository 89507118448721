import {Injectable, NgZone, Optional} from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import {FCM} from '@capacitor-community/fcm';
import {IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {ApiService} from './api.service';
import {LocalNotifications} from "@capacitor/local-notifications";
import * as moment from "moment";
import {NotificationsPage} from "../pages/notifications/notifications.page";
import {PushNotificationModel} from "../models/pushNotification.model";
import {BehaviorSubject} from "rxjs";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public notifications: any = new BehaviorSubject<PushNotificationModel[]>(null);
  public sharedNotifications: any = this.notifications.asObservable();

  public unreadedCount: any = new BehaviorSubject<PushNotificationModel[]>(null);
  public sharedUnreadedCount: any = this.unreadedCount.asObservable();

  constructor(
    private apiSvc: ApiService,
    private ngZone: NgZone,
    private platform: Platform,
    private modalCtrl: ModalController,
    @Optional() private readonly routerOutlet?: IonRouterOutlet
  ) {
  }

  public async getAll(type: string): Promise<Array<PushNotificationModel>> {
    return this.notifications.next(await this.apiSvc.send<Array<PushNotificationModel>>('GET', 'notifications?type=' + type, null, false));
  }

  public async getUnreaded(): Promise<number> {
    const resp = await this.apiSvc.send<Array<PushNotificationModel>>('GET', 'notifications/unreaded', null, false);
    if (resp) {
      return this.unreadedCount.next(resp.length);
    }
    return 0;
  }

  public async markAsRead(notification: PushNotificationModel): Promise<Array<PushNotificationModel>> {
    if (!notification.readAt) {
      const data = {
        notificationIds: [notification.id]
      };
      const resp = await this.apiSvc.send<Array<PushNotificationModel>>('POST', 'notifications/read', data)
      if (this.unreadedCount.getValue() > 0) {
        this.unreadedCount.next(this.unreadedCount.getValue() - 1);
      } else {
        this.unreadedCount.next(0);
      }
      if (this.notifications.getValue()) {
        const notifications = this.notifications.getValue();
        for (const notif of resp) {
          console.log("unread notif", notifications.findIndex(n => n.id === notif.id))
          notifications[notifications.findIndex(n => n.id === notif.id)] = notif;
        }
        return this.notifications.next(notifications);
      }
      return resp;
    }
  }

  public async markAllAsRead(): Promise<Array<PushNotificationModel>> {
    if (this.unreadedCount.getValue() > 0) {
      const array = [];
      for (const notif of this.notifications.getValue()) {
        if (!notif.readAt) {
          array.push(notif.id);
        }
      }
      const data = {
        notificationIds: array
      };
      const resp = await this.apiSvc.send<Array<PushNotificationModel>>('POST', 'notifications/read', data);
      this.unreadedCount.next(0);
      const notifications = this.notifications.getValue();
      for (const notif of resp) {
        notifications[notifications.findIndex(n => n.id === notif.id)] = notif;
      }
      return this.notifications.next(notifications);
    }
  }


  public async registerToken(data): Promise<void> {
    return await this.apiSvc.send<any>('PUT', 'notifications/token', data);
  }


  public async destroy(): Promise<void> {
    await PushNotifications.removeAllListeners().then((resp) => {
      console.log('REMOVE ALL PUSH LISTENERS', resp);
    });
  }

  public async notificationsUnsubscribe(topics: Array<string>): Promise<void> {
    for (const topic of topics) {
      await this.topicUnsubscribe(topic);
    }
  }

  public async topicSubscribe(topic: string): Promise<void> {
    if (this.platform.platforms().includes('capacitor')) {
      FCM.subscribeTo({topic})
        .then((r) => {
          return r;
        })
        .catch((err) => {
          Sentry.captureException(JSON.stringify(err));

          console.log(err)
        });
    }
  }

  public async topicUnsubscribe(topic: string): Promise<void> {
    if (this.platform.platforms().includes('capacitor')) {
      FCM.unsubscribeFrom({topic})
        .then((r) => {
          return r;
        }).catch((err) => {
        Sentry.captureException(JSON.stringify(err));
        console.log(err)
      });
    }
  }

  public async unregister(): Promise<void> {
    await PushNotifications.unregister().then((resp) => {
      console.log('UNREGISTER RESP:', resp);
      this.registerToken({
        token: ''
      })
      this.destroy()
    });
  }
  public async init(): Promise<void> {
    await PushNotifications.requestPermissions().then(async result => {
          console.log('REQUEST PERM RESP:', result);
      if (result.receive === 'granted') {
        PushNotifications.register().then((resp) => {
          console.log('REGISTER PUSH RESP:', resp);
        });
      } else {
        console.log('PUSH PERMISSION DENIED', result);
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    await PushNotifications.addListener('registration',
      async (token: Token) => {
        FCM.getToken()
          .then(async (r) => {
            console.log(`FCM Token ${r.token}`);
            this.registerToken({
                token: r.token
              }
            )
          })
          .catch((err) => {
            Sentry.captureException(JSON.stringify(err));

            console.log(err)
          });
      }
    );

    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ', error);
      }
    );

    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ', notification);
        await PushNotifications.getDeliveredNotifications().then((push) => {
          PushNotifications.removeDeliveredNotifications(push);
        });
        if (this.platform.is('android')) {
          await LocalNotifications.createChannel({
            id: 'pushChannel',
            name: 'Local Push channel',
            description: 'Default channel for local notifications',
            importance: 5,
            visibility: 1
          });

          //local notification due to show it in status bar and play sound
          await LocalNotifications.schedule({
            notifications: [
              {
                title: notification.title,
                body: notification.body,
                id: Number(moment().valueOf().toString().substring(0, 9)),
                extra: notification.data,
                channelId: 'pushChannel',
                silent: true
              }
            ]
          });
        }
      }
    );
    await LocalNotifications.addListener('localNotificationReceived', (localNotification) => {
      console.log('Local Notification action received', localNotification);
    });

    await LocalNotifications.addListener('localNotificationActionPerformed', (localNotification) => {
      console.log('Local Notification action performed received', localNotification);
      this.handleNotificationClick(localNotification.notification);
    });
    // Method called when tapping on a notification
    await PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ', notification);
        /*
        const push: { extra: { articleId: string, articleType: string } } = {
          extra: {
            articleId: notification.notification.data.articleId,
            articleType: notification.notification.data.articleType,
          }
        }*/
        this.handleNotificationClick(notification);
      }
    );

  }

  private async handleNotificationClick(notification): Promise<void> {
    console.log('HANDLE CLICK: ', notification);
    await PushNotifications.removeAllDeliveredNotifications();
    await LocalNotifications.removeAllDeliveredNotifications();

    await this.ngZone.run(async () => {
      const modal = await this.modalCtrl.create({
        component: NotificationsPage,
        canDismiss: true,
        componentProps: {
          clickedNotification: notification
        },
        presentingElement: this.routerOutlet == null ? null : this.routerOutlet.nativeEl,
      });
      return await modal.present();
    });
    /*if (notification.extra && notification.extra.articleId) {
      let url: string = '';
      /!*switch (notification.extra.articleType) {
        case 'press_release':
          url = 'app/news/' + notification.extra.articleId
          break;
        case 'story':
          url = 'app/stories/' + notification.extra.articleId
          break;
        case 'model':
          url = 'app/more-options/models/' + notification.extra.articleId + '/1009/detail'
          break;
      }

      await this.ngZone.run(async () => {
        await this.router.navigate([url]);
      })*!/
    }*/
  }
}
