import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ToastController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private apiSvc: ApiService,
    private toastCtrl: ToastController,
    private translateSvc: TranslateService
  ) {
  }

  async canActivate(): Promise<boolean> {
    //if (this.apiSvc.isLogged && this.apiSvc.isValid && this.authSvc.profile && !this.authSvc.profile.verified) {
  /*  if (this.apiSvc.isLogged && this.apiSvc.isValid) {
      await this.router.navigateByUrl('/auth/activation');
      return false;
    }*/
    if (!this.apiSvc.isLogged || !this.apiSvc.isValid) {
      await this.router.navigateByUrl('/auth/sign-in');
      return false;
    }
    console.warn("auth guard this.apiSvc.isLogged ", this.apiSvc.isLogged )
    console.warn("auth guard this.apiSvc.isValid", this.apiSvc.isValid)
    console.warn("auth guard this.authSvc.profile", this.authSvc.profile)
    //console.warn("auth guard this.authSvc.profile.verified", this.authSvc.profile.verified)
    //console.warn(this.apiSvc.isLogged && this.apiSvc.isValid && (this.authSvc.profile && this.authSvc.profile.verified))
    //return this.apiSvc.isLogged && this.apiSvc.isValid && (this.authSvc.profile && this.authSvc.profile.verified);
    return this.apiSvc.isLogged && this.apiSvc.isValid;
  }
}
