import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {LoadingHelper} from '../helpers/loading.helper';
import {environment} from '../../environments/environment';
import {LinkedinService} from './linkedin.service';
import {LinkedIn} from 'ng2-cordova-oauth/core';
import {OauthCordova} from 'ng2-cordova-oauth/platform/cordova';
import * as Sentry from '@sentry/capacitor';
import {InitializeOptions, SocialLogin} from "@capgo/capacitor-social-login";
import {Capacitor} from "@capacitor/core";
import {SignInWithApple, SignInWithAppleOptions} from "@capacitor-community/apple-sign-in";
import {Preferences} from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class SocialsService {


  constructor(
    private loadingHelper: LoadingHelper,
    private linkedinSvc: LinkedinService
  ) {
  }

  logFB(resp) {
    console.warn(resp)
  }

  async facebookLoginApi(): Promise<any> {
    let options: InitializeOptions = {};
    options = {
      facebook: {
        appId: environment.facebookAppId,
        clientToken: environment.facebookAppClientToken,
      },
    }
    await this.loadingHelper.presentLoader(null, null, 'facebookLoginApi()');
    try {
      await SocialLogin.initialize(options);
      const res = await SocialLogin.login({
        provider: 'facebook',
        options: {
          permissions: ['email', 'public_profile']
        },
      });
      console.warn("resp facebook", res)
      if (res && res.result.accessToken.token) {
        if (!res.result.profile.email) {
          // @ts-ignore
          return fetch('https://graph.facebook.com/' + (res.result.accessToken.userId ? res.result.accessToken.userId : res.result.accessToken.userID) + '?fields=id,name,email,picture&&access_token=' + res.result.accessToken.token, {})
            .then(response => response.json())
            .then(data => {
              const profile = {
                token: res.result.accessToken.token,
                // @ts-ignore
                email: data.email,
                // @ts-ignore
                fullName: data.name
              };
              return profile;
            })
            .catch(error => {
              Sentry.captureException(JSON.stringify(error));
              console.error('Error fetching user profile:', error);
              return error
            })
        } else {
          let profile = {
            token: res.result.accessToken.token,
            email: res.result.profile.email,
            // @ts-ignore
            fullName: res.result.profile.name
          };
          if (Capacitor.getPlatform() === 'ios') {
            // @ts-ignore
            profile.token = res.result.idToken
          }

          return profile
        }
      }
    } catch (e) {
      console.warn("FACEBOOK LOGIN ERROR", e)
      Sentry.captureException('Error logging into FB: ' + JSON.stringify(e));
    }
    /* try {
       const FACEBOOK_PERMISSIONS = [
         'email',
         'public_profile'
       ];
       const resultFbLogin = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
       await this.loadingHelper.presentLoader(null, null, 'facebookLoginApi()');
       if (resultFbLogin.accessToken) {
         const resultGetFbProfile = await FacebookLogin.getProfile<any>({ fields: ['id', 'email', 'first_name', 'last_name'] });
         return { profile: resultGetFbProfile, login: resultFbLogin };
       }
     } catch (e) {
       console.log('Error logging into FB', e);
       Sentry.captureException('Error logging into FB: ' + JSON.stringify(e));
       return e;
     }*/
  }

  async googleLoginApi(): Promise<any> {
    let options: InitializeOptions = {};
    if (Capacitor.getPlatform() === 'ios') {
      options = {
        google: {
          iOSClientId: environment.googleIosClientId // the iOS client id
        },
      }
    } else {
      options = {
        google: {
          webClientId: environment.googleWebClientId // the web client id for Android and Web
        }
      }
    }

    try {
      const googleInit = await SocialLogin.initialize(options);
      console.warn(googleInit)
      await this.loadingHelper.presentLoader(null, null, 'googleLoginApi()');
      let loginScopes = {}
      if (Capacitor.getPlatform() !== 'web') {
        loginScopes = {
          scopes: ['email', 'profile'],
        }
      }
      const res = await SocialLogin.login({
        provider: 'google',
        options: loginScopes,
      });

      if (res && res.result.accessToken.token) {
        const profile = {
          token: res.result.accessToken.token,
          email: res.result.profile.email,
          // @ts-ignore
          fullName: res.result.profile.name
        };
        return profile;
      }
    } catch (e) {
      console.warn("GOOGLE LOGIN ERROR", e)
      Sentry.captureException('Error logging into GOOGLE: ' + JSON.stringify(e));
    }
  }

  async linkedInLoginApi(): Promise<any> {
    try {
      const provider = new LinkedIn({
        clientId: environment.linkedinClientId,
        appScope: ['r_emailaddress', 'r_liteprofile'],
        redirectUri: 'http://localhost/callback',
        responseType: 'code',
        state: this.linkedinSvc.getRandomState()
      });
      const oauth = new OauthCordova();
      const resp = await oauth.logInVia(provider);
      // @ts-ignore
      const respToken = await this.linkedinSvc.getAccessToken(resp.code);
      await this.loadingHelper.presentLoader(null, null, 'linkedInLoginApi()');
      if (respToken) {
        const accessToken = respToken.data.access_token;
        const namePromise = await this.linkedinSvc.getName(accessToken);
        //const picPromise = await this.linkedinSvc.getProfilePic(accessToken);
        const emailPromise = await this.linkedinSvc.getEmail(accessToken);

        const profile = {
          token: accessToken,
          email: emailPromise,
          fullName: namePromise
        };
        return profile;
      }
    } catch (e) {
      console.log('Error logging into LinkedIn', e);
      Sentry.captureException('Error logging into LinkedIn: ' + JSON.stringify(e));
      return e;
    }
  }

  async appleLoginApi(): Promise<any> {
    /*let options: InitializeOptions = {};
    options = {
      apple: {
        clientId: 'cz.vipbenefitclub.app',
        redirectUrl: 'https://app.vip-host.techcrowd.space',
      },

    }
    await SocialLogin.initialize(options);
    await this.loadingHelper.presentLoader(null, null, 'appleLoginApi()');

    try {
      const res = await SocialLogin.login({
        provider: 'apple',
        options: {
          scopes: ['email', 'name'],
        },
      });
      if (res && res.result.accessToken.token) {
        const profile = {
          token: res.result.accessToken.token,
          email: res.result.profile.email,
          // @ts-ignore
          fullName: res.result.profile.name
        };
        return profile;
      }
    } catch (e) {
      console.warn("APPLE LOGIN ERROR", e)
      Sentry.captureException('Error logging into Apple: ' + JSON.stringify(e));
    }*/
    try {
      const options: SignInWithAppleOptions = {
        clientId: 'cz.vipbenefitclub.app',
        redirectURI: 'https://app.vipbenefitclub.cz',
        scopes: 'email name'
      };
      const resp = await SignInWithApple.authorize(options);
      await this.loadingHelper.presentLoader(null, null, 'appleLoginApi()');
      if (resp.response && resp.response.identityToken) {
        if (resp.response.email) {
          await Preferences.set({key: 'apple-credentials', value: JSON.stringify(resp.response)});
          const profile = {
            token: resp.response.identityToken,
            email: resp.response.email,
            fullName: resp.response.givenName + ' ' + resp.response.familyName
          };
          return profile;
        } else {
          const {value} = await Preferences.get({key: 'apple-credentials'});
          if (value && value !== 'undefined') {
            const profile = {
              token: JSON.parse(value).identityToken,
              email: JSON.parse(value).email,
              fullName: JSON.parse(value).givenName + ' ' + JSON.parse(value).familyName
            };
            return profile;
          } else {
            const profile = {
              token: resp.response.identityToken,
              email: this.getDecodedAccessToken(resp.response.identityToken).email,
              fullName: resp.response.givenName + ' ' + resp.response.familyName
            };
            return profile;
          }
        }
      }
    } catch (e) {
      console.log('Error logging into Apple', e);
      Sentry.captureException('Error logging into Apple: ' + JSON.stringify(e));
      return e;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }


}
