// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-vipbc.techcrowd.space/api/',
  baseUrl: 'https://api-vipbc.techcrowd.space/',
  filesBaseUrl: 'https://dev-vipbc.techcrowd.space',
  linkedinClientId: '786trdpxfxc5nv',
  linkedinClientSecret: 'WPL_AP1.dNH85TzEWrw8ho77.mvOfSg==',
  googleMapsApiKey: 'AIzaSyCnrwLX7Dxr0UmkF7wpT99i9Sj5I12s5tw',
  sentryUrl: 'https://44f75e31ee6d4134b7ced58529b78133@o4505158360039424.ingest.sentry.io/4505158367313920',
  appId: '0868c3aa-521e-48c1-aa8e-44b1b1113620',
  appIdSalt: '*QSd)69(/*xK',
  facebookAppId: '944544247551252',
  facebookAppClientToken: 'ee848c4d193bd70c5ea4c9be963b118f',
  googleIosClientId: '748296497867-dfiv19p4e300jmrh502oj8ehpavl2vp9.apps.googleusercontent.com',
  googleWebClientId:'748296497867-eia1u1majn7v99ieevbh0h4uspfa19jd.apps.googleusercontent.com',
  googleAndroidClientId:'748296497867-0kblm43kjt61f7f1sk70coai9uinig87.apps.googleusercontent.com',
  firebaseConfig: {
    apiKey: 'AIzaSyAGpfAmjVqLis74VEIl15YuCsBqxlP6OKg',
    authDomain: 'techcrowd-vipbc.firebaseapp.com',
    projectId: 'techcrowd-vipbc',
    storageBucket: 'techcrowd-vipbc.appspot.com',
    messagingSenderId: '748296497867',
    appId: '1:748296497867:web:b83ad427756f35d553094e',
    measurementId: 'G-D63LTYN1P5'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
